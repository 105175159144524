/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";
import { AiOutlineMail } from "react-icons/ai";
// import { BsFillPersonLinesFill } from "react-icons/bs";
import { FaGit, FaLinkedin } from "react-icons/fa6";
// import CV from "../assets/cv-fixed-now.pdf";

const Contact = () => {
  return (
    <div id="contact" className="w-full text-[#fbfbfb] ">
      <div className="flex flex-col">
        <div className="text-[20px] md:text-[40px] font-sora text-[#fbfbfb] md:leading-[72px]">
          Menghubungi Saya
        </div>
      </div>
      <div className="m-auto p-4 w-full font-sora">
        <div className="grid p-4 lg:grid-cols-5 gap-8">
          {/* Left */}
          <div className="col-span-3 lg:col-span-2 w-full shaddow-xl bg-[#323443] rounded-xl p-4">
            <div className="lg:p-4 h-full">
              <div>
                <img
                  src={require("../assets/project-unsplash1.jpg")}
                  alt=""
                  className="rounded-xl hover:scale-105 ease-in duration-300"
                />
              </div>
              <div className="font-poppins">
                <h2 className="py-2 text-white font-bold text-2xl ">Tarwin</h2>
                <p>It Support</p>
                <p className="py-4">Hubungi Saya</p>
              </div>
              <p className="uppercase underline pt-8 font-poppins font-bold text-center">
                Terhubung Dengan Saya
              </p>
              <div className="flex items-center justify-between py-4">
                <a href="">
                  <div className="rounded-full shadow-lg hover:shadow-white bg-[linear-gradient(134deg,_#3BF686_40.75%,_#4CA9FF_90.52%)] md:p-6 p-3 cursor-pointer hover:scale-110 ease-in duration-300">
                    <FaLinkedin />
                  </div>
                </a>
                <a href="">
                  <div className="rounded-full shadow-lg hover:shadow-white bg-[linear-gradient(134deg,_#3BF686_40.75%,_#4CA9FF_90.52%)] md:p-6 p-3 cursor-pointer hover:scale-110 ease-in duration-300">
                    <FaGit />
                  </div>
                </a>
                <a href="">
                  <div className="rounded-full shadow-lg hover:shadow-white bg-[linear-gradient(134deg,_#3BF686_40.75%,_#4CA9FF_90.52%)] md:p-6 p-3 cursor-pointer hover:scale-110 ease-in duration-300">
                    <AiOutlineMail />
                  </div>
                </a>
                {/* <a
                  href={CV}
                  target="_blank"
                  download="CV-tarwin"
                  rel="noreferrer"
                >
                  <div className="rounded-full shadow-lg hover:shadow-white bg-[linear-gradient(134deg,_#3BF686_40.75%,_#4CA9FF_90.52%)] md:p-6 p-3 cursor-pointer hover:scale-110 ease-in duration-300">
                    <BsFillPersonLinesFill />
                  </div>
                </a> */}
              </div>
            </div>
          </div>

          {/* Right */}
          <div className="col-span-3 w-full h-auto shadow-xl bg-[#323443] rounded-xl lg:p-4">
            <div className="p-4">
              <form
                action="https://getform.io/f/aqonrdya"
                method="POST"
                encType="multipart/form-data"
                target="_blank"
                className="text-white"
              >
                <div className="grid md:grid-cols-2 gap-4 w-full py-2">
                  <div className="flex flex-col">
                    <label className="uppercase text-sm py-2">Nama</label>
                    <input
                      type="text"
                      name="name"
                      className="border-2 rounded-lg p-3 flex border-slate-300 outline-bg-[linear-gradient(134deg,_#3BF686_40.75%,_#4CA9FF_90.52%)] text-black"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="uppercase text-sm py-2">No Telepon</label>
                    <input
                      type="text"
                      name="nohp"
                      className="border-2 rounded-lg p-3 flex border-slate-300 outline-bg-[linear-gradient(134deg,_#3BF686_40.75%,_#4CA9FF_90.52%)] text-black"
                    />
                  </div>
                </div>
                <div className="flex flex-col py-2">
                  <label className="uppercase text-sm py-2">Email</label>
                  <input
                    type="text"
                    name="email"
                    className="border-2 rounded-lg p-3 flex border-slate-300 outline-bg-[linear-gradient(134deg,_#3BF686_40.75%,_#4CA9FF_90.52%)] text-black"
                  />
                </div>
                <div className="flex flex-col py-2">
                  <label className="uppercase text-sm py-2">Subjek</label>
                  <input
                    type="text"
                    name="subjek"
                    className="border-2 rounded-lg p-3 flex border-slate-300 outline-bg-[linear-gradient(134deg,_#3BF686_40.75%,_#4CA9FF_90.52%)] text-black"
                  />
                </div>
                <div className="flex flex-col py-2">
                  <label className="uppercase text-sm py-2">Pesan</label>
                  <textarea
                    rows="10"
                    name="subjek"
                    className="border-2 rounded-lg p-3 flex border-slate-300 outline-bg-[linear-gradient(134deg,_#3BF686_40.75%,_#4CA9FF_90.52%)] text-black"
                  />
                </div>
                <button className="w-full rounded-xl p-4 bg-[linear-gradient(134deg,_#3BF686_40.75%,_#4CA9FF_90.52%)]">
                  Kirim Pesan
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
