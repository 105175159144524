import "./App.css";
import { useRef } from "react";
import About from "./components/About";
import Contact from "./components/Contact";
import Main from "./components/Main";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Skills from "./components/Skills";

function App() {
  // Membuat ref untuk setiap section
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  const mainRef = useRef(null);
  const projectsRef = useRef(null);
  const skillsRef = useRef(null);

  // Fungsi scroll untuk memindahkan posisi ke section tujuan
  const scrollToSection = (section) => {
    const sectionRefs = {
      about: aboutRef,
      contact: contactRef,
      main: mainRef,
      projects: projectsRef,
      skills: skillsRef,
    };

    if (sectionRefs[section]?.current) {
      window.scrollTo({
        top: sectionRefs[section].current.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      id="porto"
      className="px-[30px] md:px-[100px] w-full bg-black-2 flex flex-col"
    >
      {/* Pass fungsi scrollToSection ke Navbar */}
      <Navbar scrollToSection={scrollToSection} />

      <div className="mt-[70px] md:mt-[100px]">
        <hr className="bg-[#4d4d4d] border-none h-[1px]" />

        <div ref={mainRef}>
          <Main />
        </div>

        <hr className="bg-[#4d4d4d] border-none h-[1px] my-5" />

        <div ref={aboutRef}>
          <About />
        </div>

        <hr className="bg-[#4d4d4d] border-none h-[1px] my-5" />

        <div ref={skillsRef}>
          <Skills />
        </div>

        <hr className="bg-[#4d4d4d] border-none h-[1px] my-5" />

        <div ref={projectsRef}>
          <Projects />
        </div>

        <hr className="bg-[#4d4d4d] border-none h-[1px] my-5" />

        <div ref={contactRef}>
          <Contact />
        </div>
      </div>
    </div>
  );
}

export default App;
