import React from "react";
import { facebook, globe, instagram, twitter } from "../assets/svg/svg";

const Main = () => {
  return (
    <div id="home" className="py-[30px] flex flex-col ">
      <div className="font-sora font-bold md:text-[56px] text-[24px] md:leading-[72px] md:tracking-[1px] w-full md:w-[80%] bg-[linear-gradient(134deg,_#3BF686_40.75%,_#4CA9FF_90.52%)] text-transparent bg-clip-text">
        Hi saya tarwin, saya bekerja sebagai IT Support di perusahaan Lviors
        Jaya Sentosa cabang Jakarta
      </div>
      <div className="flex flex-col md:flex-row gap-[30px] mt-[35px] md:mt-[69px]">
        <div className="flex-[2]">
          <div className="text-[15px] md:text-[28px] font-sora text-[#d6d6d5] md:leadinf-[72px]">
            Apa yang saya lakukan
          </div>
          <div className="text-[10px] md:text-[18px] text-[#d6d6d6]">
            Memastikan komputer yang digunakan dapat berfungsi normal/berjalan
            seperti seharusnya,Harus memastikan bahwa semua komputer yang
            digunakan oleh pengguna terhubung ke jaringan,Bertanggung jawab
            terhadap mesin pendukung semacam printer, scanner dan lain
            sebagainya,Bertugas juga untuk cek dan perbaiki bila sewaktu-waktu
            ada masalah pada jaringan komputer
          </div>
        </div>
        <div className="cursor-pointer flex flex-col items-center justify-center md:justify-normal md:flex-row gap-[30px] flex-[3]">
          <div className="relative flex flex-col items-center justify-center px-3 pt-8 pb-4 w-[262px] bg-[#323443] rounded-[12px] mt-[36px] md:mt-0 hover:scale-110 ease-in-out duration-300">
            <div className="absolute top-[-36px] p-[1px] rounded-full bg-[linear-gradient(134deg,_#3BF686_40.75%,_#4CA9FF_90.52%)]">
              <div className="bg-[#3a3636] p-[17px] rounded-full ">{globe}</div>
            </div>
            <div className="font-sora text-white text-[16px] font-semibold leading-[72px] tracking-[px]">
              Freelance
            </div>
            <div className="text-slate-400 text-[14px] tracking-[1px] leading-6">
              Membuat surat undangan online & install office beserta upgrade SSD
              laptop
            </div>
          </div>
          <div className="relative flex flex-col items-center justify-center px-3 pt-8 pb-4 w-[262px] bg-[#323443] rounded-[12px] mt-[36px] md:mt-0 hover:scale-110 ease-in-out duration-300">
            <div className="absolute top-[-36px] p-[1px] rounded-full bg-[linear-gradient(134deg,_#3BF686_40.75%,_#4CA9FF_90.52%)]">
              <div className="bg-[#3a3636] p-[17px] rounded-full ">{globe}</div>
            </div>
            <div className="font-sora text-white text-[16px] font-semibold leading-[72px] tracking-[px]">
              Front-end developer
            </div>
            <div className="text-slate-400 text-[14px] tracking-[1px] leading-6">
              Bertanggung jawab atas tampilan website, seperti merancang tata
              letak fisik setiap halaman,dan menggunakan HTML dan JavaScript.
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row mt-[35px] md:mt-[45px]">
        <div className="flex md:items-start items-center justify-center md:justify-normal gap-3">
          <div className="md:w-[159px] md:h-[159px] w-[50px] h-[50px] rounded-full bg-[linear-gradient(134deg,_#3BF686_40.75%,_#4CA9FF_90.52%)] flex justify-center items-center">
            <div className="md:w-[157px] md:h-[157px] w-[49px] h-[49px] rounded-full bg-[#3a3636]">
              <img
                src={require("../assets/icon-portofolio.png")}
                alt="Icon-portfolio"
              />
            </div>
          </div>

          <div className="flex flex-col ml-2 md:ml-9 mr-5 flex-1 max-w-[499px]">
            <div className="text-[15px] md:text-[28px] font-sora text-[#fbfbfb] md:leading-[72px]">
              Tujuan Hidup
            </div>
            <div className="text-[10px] md:text-[18px] text-[#d6d6d6]">
              Walau dalam perjalanan ini rasa ingin menyerah terkadang muncul,
              tapi ingatlah bahwa kamu masih memiliki tanggung jawab dalam
              kehidupan yang harus diperjuangkan
            </div>
          </div>
        </div>
        <div className="md-[35px] md:mt-0 md:ml-[86px] flex flex-col gap-3">
          <div className="font-sora text-[15px] md:text-[28px] text-[#fbfbfb] md:leading-[72px]">
            Mari Terhubung
          </div>
          <div className="flex gap-[17px]">
            <div className="hover:scale-[150%] ease-in-out duration-300 cursor-pointer">
              {instagram}
            </div>
            <div className="hover:scale-[150%] ease-in-out duration-300 cursor-pointer">
              {twitter}
            </div>
            <div className="hover:scale-[150%] ease-in-out duration-300 cursor-pointer">
              {facebook}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
