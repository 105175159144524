import React from "react";

const About = () => {
  return (
    <div>
      <div
        id="about"
        className="text-[20px] md:text-[48px] font-sora text-[#fbfbfb] md:leading-[72px]"
      >
        Tentang Saya
      </div>
      <div className="flex flex-col items-center xl:items-stretch xl:flex-row gap-9 mt-[40px]">
        <img
          className="object-cover w-[300px] h-[300px] md:w-[400px] md:h-[400px] rounded-[20px]"
          src={require("../assets/tarwin.jpg")}
          alt="Foto About"
        />
        <div className="bg-[#323443] p-[20px] text-slate-400 rounded-[20px] md:mr-20">
          Lulusan S1 Teknik Informatika dari Universitas Indraprasta PGRI
          (UNINDRA). Menguasai bahasa pemrograman JavaScript, MySql, dan PHP.
          Menguasai program Power Query untuk analisa dan pengelolaan data. Saat
          ini bekerja sebagai it support untuk perusahaan kecantikan di Jakarta.
          Memiliki kemampuan berpikir yang kreatif dan mampu mengelola waktu
          secara efektif. Punya kemampuan komunikasi yang baik dan bisa
          berkolaborasi dalam tim. Terbuka untuk lowongan kerja sebagai it
          support, front end developer.
        </div>
      </div>
    </div>
  );
};

export default About;
