import React from "react";
import ProjectItem from "./ProjectItem";
import ProjectBackground from "../assets/project-unsplash1.jpg";

const Projects = () => {
  return (
    <div id="projects" className="w-full">
      <div className="flex flex-col">
        <div className="text-[20px] md:text-[40px] font-sora text-[#fbfbfb] md:leading-[72px]">
          Apa yang telah saya buat
        </div>
      </div>
      <div className="grid p-8 md:p-6 md:grid-cols-2 gap-8">
        {/* Project Item */}
        <ProjectItem
          title="Undangan Online"
          backgroundImg={ProjectBackground}
          projectUrl="http://tarwinaja.github.io/tarmela-wedding"
          tect="PHP"
        />
      </div>
      <div className="grid p-8 md:p-6 md:grid-cols-2 gap-8">
        {/* Project Item */}
        <ProjectItem
          title="Typing Speed Test"
          backgroundImg={ProjectBackground}
          projectUrl="https://itnewbe.github.io/Typing_sped_test/"
          tect="JS"
        />
      </div>
    </div>
  );
};

export default Projects;
