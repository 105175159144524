/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";
import { FaCss3Alt, FaHtml5, FaNode, FaReact } from "react-icons/fa";
import { SiJavascript, SiMysql, SiPhp, SiTailwindcss } from "react-icons/si";

const Skills = () => {
  return (
    <div id="skills">
      <div className="text-[20px] md:text-[40px] font-sora text-[#fbfbfb] md:leading-[72px]">
        Ini adalah skills yang saya punya
      </div>
      <div className="cursor-pointer grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 p-4 min-[1300px]:grid-cols-4 gap-8">
        <div className="p-6 shadow-xl rounded-xl hover:shadow-xl hover:shadow-[#3bf686] bg-slate-400 text-navbar hover:scale-[1.1] ease-in duration-300">
          <div className="grid grid-cols-2 gap-4 justify-center items-center">
            <div className="m-auto">
              <FaHtml5 className="text-8xl" />
            </div>
            <div className="flex flex-col items-center justify-center">
              <h3 className="text-4xl">HTML</h3>
            </div>
          </div>
        </div>
        <div className="p-6 shadow-xl rounded-xl hover:shadow-xl hover:shadow-[#3bf686] bg-slate-400 text-navbar hover:scale-[1.1] ease-in duration-300">
          <div className="grid grid-cols-2 gap-4 justify-center items-center">
            <div className="m-auto">
              <FaReact className="text-8xl" />
            </div>
            <div className="flex flex-col items-center justify-center">
              <h3 className="text-3xl">REACT</h3>
            </div>
          </div>
        </div>

        <div className="p-6 shadow-xl rounded-xl hover:shadow-xl hover:shadow-[#3bf686] bg-slate-400 text-navbar hover:scale-[1.1] ease-in duration-300">
          <div className="grid grid-cols-2 gap-4 justify-center items-center">
            <div className="m-auto">
              <FaCss3Alt className="text-8xl" />
            </div>
            <div className="flex flex-col items-center justify-center">
              <h3 className="text-3xl">CSS</h3>
            </div>
          </div>
        </div>
        <div className="p-6 shadow-xl rounded-xl hover:shadow-xl hover:shadow-[#3bf686] bg-slate-400 text-navbar hover:scale-[1.1] ease-in duration-300">
          <div className="grid grid-cols-2 gap-4 justify-center items-center">
            <div className="m-auto">
              <FaNode className="text-8xl" />
            </div>
            <div className="flex flex-col items-center justify-center">
              <h3 className="text-3xl">NODE JS</h3>
            </div>
          </div>
        </div>
        <div className="p-6 shadow-xl rounded-xl hover:shadow-xl hover:shadow-[#3bf686] bg-slate-400 text-navbar hover:scale-[1.1] ease-in duration-300">
          <div className="grid grid-cols-2 gap-4 justify-center items-center">
            <div className="m-auto">
              <SiJavascript className="text-8xl" />
            </div>
            <div className="flex flex-col items-center justify-center">
              <h3 className="text-3xl">Javascript</h3>
            </div>
          </div>
        </div>
        <div className="p-6 shadow-xl rounded-xl hover:shadow-xl hover:shadow-[#3bf686] bg-slate-400 text-navbar hover:scale-[1.1] ease-in duration-300">
          <div className="grid grid-cols-2 gap-4 justify-center items-center">
            <div className="m-auto">
              <SiPhp className="text-8xl" />
            </div>
            <div className="flex flex-col items-center justify-center">
              <h3 className="text-3xl">PHP</h3>
            </div>
          </div>
        </div>
        <div className="p-6 shadow-xl rounded-xl hover:shadow-xl hover:shadow-[#3bf686] bg-slate-400 text-navbar hover:scale-[1.1] ease-in duration-300">
          <div className="grid grid-cols-2 gap-4 justify-center items-center">
            <div className="m-auto">
              <SiTailwindcss className="text-8xl" />
            </div>
            <div className="flex flex-col items-center justify-center">
              <h3 className="text-3xl">TAILWIND CSS</h3>
            </div>
          </div>
        </div>
        <div className="p-6 shadow-xl rounded-xl hover:shadow-xl hover:shadow-[#3bf686] bg-slate-400 text-navbar hover:scale-[1.1] ease-in duration-300">
          <div className="grid grid-cols-2 gap-4 justify-center items-center">
            <div className="m-auto">
              <SiMysql className="text-8xl" />
            </div>
            <div className="flex flex-col items-center justify-center">
              <h3 className="text-3xl">Mysql</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
